import React from "react";
import Wallet from "./Wallet";

const Body = () => {
  return (
    <div className="body">
      <Wallet />
    </div>
  );
};

export default Body;
