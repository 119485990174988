import React from "react";

const WalletTableHead = () => {
  return (
    <tr>
      <th>Asset</th>
      <th>Amount</th>
      <th>Exchanage</th>
      <th>Price</th>
      <th>Total Value</th>
    </tr>
  );
};

export default WalletTableHead;
